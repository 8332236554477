@use 'src/variables' as v;

.main {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 312px;
  align-self: center;
  padding-top: 2rem;


  @include v.medium {
    align-items: center;
    width: 400px;
  }

  @include v.mediumMax {
    h2 {
      margin-top: 1rem;
    }
  }

}

.form {
  display: flex;
  align-items: center;

  @include v.mediumMax {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  input {
    border-radius: 5px;
    border: 1px solid black;
    height: 40px;
    margin-right: 1rem;
    padding-left: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
    width: 100%;

    @include v.medium {
      width: 280px;
    }

  }

  @include v.mediumMax {
    label {
      margin-top: 0.5rem;
    }
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
  align-self: stretch;

  input {
    border-radius: 5px;
    border: 1px solid black;
    height: 40px;
    margin-right: 1rem;
    padding-left: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
    width: 100%;
  }
}

.button {
  border-radius: 5px;
  height: 40px;
  border: none;
  background-color: v.$primary-color;
  color: white;
  transition: 0.3s;
  box-shadow: var(--box-shadow-default);
  flex-grow: 1;
  align-self: stretch;
  margin-top: 2rem;

  &:active {
    box-shadow: none;
  }

  &:hover, &:disabled {
    background-color: v.$primary-color-transparent;
  }
}

.errorMessage, .statusMessage {
  min-height: 1.5rem;
  margin: 1rem 0;
}

.errorMessage {
  color: red;
}

.statusMessage {
  align-self: center;
  color: var(--primary-color);
}

.message {
  max-width: 400px;
  margin-bottom: 1rem;
}
