@use "src/variables" as v;
@import "react-quill/dist/quill.snow.css";

.main {
  padding: 0 1rem 1rem;
  display: flex;
  flex-flow: column nowrap;

  @include v.mediumMax {
    h2 {
      margin-bottom: 0;
    }
  }
}

.message {
  max-width: 400px;
  margin-top: 1rem;

  @include v.mediumMax {
    margin-bottom: 1rem;
  }
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  background-color: v.$secondary-color-transparent;
  max-width: 515px;

  @include v.smallMax {
    align-items: stretch;
    width: 100%;
  }

  input,
  textarea {
    border: none;
    height: 40px;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.5rem;

    @include v.small {
      // width: 350px;
    }

    @include v.medium {
      width: 500px;
    }

    .subjectInput {
      width: 100%;
    }
  }

  .newTagGroup {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: auto;

    .button {
      flex-grow: 1;
      margin: 0 0 0 0.5rem;
    }
  }

  .newTagInput {
    margin-bottom: 0;
    flex-grow: 5;
    width: auto;
  }

  fieldset {
    width: 100%;
    margin: 0;

    @include v.small {
      min-width: 500px;
    }
  }

  fieldset.languageField {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 1rem;

    .radioGroup {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    // div:first-of-type {
    //   margin-bottom: 0.5rem;
    // }

    label {
      margin-left: 0.2rem;
      line-height: 1.5rem;
    }

    input {
      appearance: none;
      border: 2px solid #999;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      transition: 0.2s all;
      margin: 0;
      padding: 0;

      &:checked {
        border: 9px solid v.$primary-color;
      }
    }

    legend {
      color: black;
      border-radius: 0;
      border: 0;
    }

    button:hover,
    button:focus {
      color: #999;
    }

    button:active {
      background-color: white;
      color: black;
      outline: 1px solid black;
    }
  }

  textarea {
    height: 200px;
    resize: vertical;
  }

  .quill {
    background: white;
    width: 100%;

    :global(.ql-container) {
      min-height: 300px;
    }

    @include v.medium {
      width: 500px;
    }
  }

  .qlContainer {
    min-height: 300px;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
  }

  .button {
    height: 40px;
    border: none;
    margin-top: 1rem;
    background-color: v.$primary-color;
    color: white;
    box-shadow: none;
    transition: 0.3s;
    flex-grow: 1;

    &:active {
      box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.75);
      // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
    }

    &:hover {
      cursor: pointer;
      // background-color: v.$primary-color-transparent;
    }

    @include v.smallMax {
      margin-top: 0.5rem;
    }
  }
}

.divider {
  width: 100%;
  border: 0;
  height: 1px;
  margin: 2rem 0;
  background-image: linear-gradient(to right,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0));
}

.errorMessage {
  max-width: calc(370px + 1rem);
  min-height: 1.5rem;
  margin-top: 1rem;
  color: red;
  display: block;
}

.formSentMessageBackground {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}

.formSentMessage {
  position: fixed;
  opacity: 0;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 320px;
  top: calc(50% - 50px);
  left: calc(50% - 160px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  z-index: 100;

  button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.2em;
  }
}

.visible {
  opacity: 1;
}