@use "src/variables" as v;

.header {
  min-height: 200px;
  position: relative;
  display: flex;
  background-image: url("../../images/header-bg-mob.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  padding: 1rem 1rem 112px;
  color: var(--primary-color);
  align-items: flex-start;
  justify-content: space-between;

  @include v.medium {
    min-height: 300px;
    // background-image: url('../../images/header-bg-tab.svg');
    padding-bottom: 125px;
  }

  @include v.large {
    background-image: url("../../images/header-bg-desk.svg");
  }

  h1 {
    margin-bottom: 2rem;
    margin-left: 1rem;
    line-height: 1.3rem;
    font-size: 1.3rem;

    @include v.medium {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }
  }

  img {
    display: block;
    width: 64px;
    height: auto;
    margin-right: 1rem;

    @include v.mediumMax {
      margin: 0;
    }
  }

  .logo {
    transition: all 0.2s;
    width: 102px;

    @include v.smallMax {
      width: 80px;
    }

    @include v.medium {
      width: 120px;
    }
  }
}

.menuIcon {
  margin: 2px 0 0;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 10;
  color: var(--primary-color);
  display: block;
  padding: 0;

  img {
    width: 38px;
    margin: 0;
  }
}

.headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 0 1 auto;
  min-width: 40%;

  @include v.mediumMax {
    flex: 1 1 auto;
  }
}

.headerButton {
  background-color: white;
  padding: 1vh;
  border-radius: 4px;
  margin-left: 1vh;
}

.menuBackground {
  display: block;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  transition: all 0.2s;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
}

.menuBackgroundOpen {
  visibility: visible;
  opacity: 1;
  z-index: 20;
}

.languageButton {
  font-family: v.$headings-font;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.4rem;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  height: 24px;
  margin-right: 6px;
  border-radius: 5px;
  color: var(--primary-color);
  background: none;
  text-transform: uppercase;
}