@use 'src/variables' as v;

.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--primary-color);
  color: white;

  @include v.mediumMax {
    justify-content: center;
  }

  h3 {
    color: var(--secondary-color);
    text-transform: uppercase;
  }

  p {
    line-height: 1.5rem;
  }

  a {
    color: white;
  }

  img {
    max-height: 120px;
  }
}
