@use "src/variables" as v;

.tagList {
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  :last-child {
    margin-right: 0;
  }
}

.tagLi {
  position: relative;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.tagDiv {
  color: v.$primary-color;
  background: white;
  height: 100%;
  width: 100%;
  padding: 0.2rem;
  border-radius: 8px;
}

input[type="checkbox"]:checked~.tagDiv {
  background: v.$primary-color;
  color: white;
}