@use "src/variables" as v;

.employee {
  display: flex;
  justify-content: space-between;
}

.deleteButton {
  font-size: 24px;
  padding: 0;
  line-height: 100%;
  background: none;
  cursor: pointer;
  color: v.$primary-color;
  border: none;
  right: 0;
}

.confirmationBackground {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 30%;
  border: none;
  border-radius: 5px;
  height: 65%;
  background-color: white;
  box-shadow: none;
  transition: 0.3s;
  line-height: 100%;

  &:active {
    box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.25);
    // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  }

  &:hover {
    cursor: pointer;
    // background-color: v.$primary-color-transparent;
  }
}

.confirmationMessage {
  padding-top: 1rem;
  position: fixed;
  opacity: 0;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  width: 320px;
  top: calc(50% - 60px);
  left: calc(50% - 160px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  z-index: 100;

  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    margin-top: 1rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);

    .delete {
      background-color: red;
      color: white;
      &:active {
        box-shadow: inset 5px 5px 15px rgba(109, 1, 1, 0.75);
        // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
      }
    }
  }
}

.visible {
  opacity: 1;
}
