@use "src/variables" as v;

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  background-color: v.$secondary-color-transparent;
  max-width: 515px;

  @include v.smallMax {
    align-items: stretch;
    width: 100%;
  }

  .fileUploadInput {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: 100%;
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
  }

  .fileUploadLabel {
    box-sizing: border-box;
    height: 250px;
    width: 500px;
    max-width: 100%;
    background-color: hsl(0, 0%, 100%);
    border: 5px dashed hsl(317, 20%, 60%);
    border-radius: 20px;
    position: relative;
    transition: 0.2s ease all;
    // Align all children to the center of the container
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    &:hover {
      background-color: hsl(317, 20%, 90%);
    }

    &:focus,
    &:focus-within {
      outline: 2px solid hsl(0, 0%, 0%);
      outline-offset: 1px;
    }
  }

  .fileUploadIcon {
    color: v.$primary-color;
    font-size: 100px;
  }

  .fileUploadText {
    color: rgba(0, 0, 0, 0.75);
  }

  .fileUploadFileName {
    color: v.$primary-color;
    font-size: 1.2rem;
  }

  fieldset {
    display: flex;
    justify-content: space-evenly;

    .radioGroup {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    label {
      margin-left: 0.2rem;
      line-height: 1.5rem;
    }

    input[type=radio] {
      appearance: none;
      border: 2px solid #999;
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      transition: 0.2s all;
      margin: 0;
      padding: 0;

      &:checked {
        border: 9px solid v.$primary-color;
      }
    }

    legend {
      color: black;
      border-radius: 0;
      border: 0;
    }

    button:hover,
    button:focus {
      color: #999;
    }

    button:active {
      background-color: white;
      color: black;
      outline: 1px solid black;
    }
  }

  .button {
    height: 40px;
    border: none;
    margin-top: 1rem;
    background-color: v.$primary-color;
    color: white;
    box-shadow: none;
    transition: 0.3s;
    flex-grow: 1;

    &:active {
      box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.75);
      // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
    }

    &:hover {
      cursor: pointer;
      // background-color: v.$primary-color-transparent;
    }

    @include v.smallMax {
      margin-top: 0.5rem;
    }
  }

  fieldset.tagField {
    justify-content: start;
    flex-direction: column;

    input {
      border: none;
      height: 40px;
      padding-left: 0.5rem;
      flex-grow: 5;
    }
  }

  div.newTagGroup {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    width: auto;
    margin-bottom: 0;

    .button {
      flex-grow: 1;
      margin: 0 0 0 0.5rem;
    }
  }
}

.tagList {
  list-style: none;
  display: flex;
  flex-flow: row wrap;

  :last-child {
    margin-right: 0;
  }
}

.tagLi {
  position: relative;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.tagDiv {
  color: v.$primary-color;
  background: white;
  height: 100%;
  width: 100%;
  padding: 0.2rem;
  border-radius: 8px;
}

input[type="checkbox"]:checked~.tagDiv {
  background: v.$primary-color;
  color: white;
}