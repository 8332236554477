// Margins

.mb0 {
  margin-bottom: 0;
}
.mb1 {
  margin-bottom: 0.25rem;
}
.mb2 {
  margin-bottom: 0.5rem;
}
.mb3 {
  margin-bottom: 0.75rem;
}
.mb4 {
  margin-bottom: 1rem;
}

.mt0 {
  margin-top: 0;
}
.mt1 {
  margin-top: 0.25rem;
}
.mt2 {
  margin-top: 0.5rem;
}
.mt3 {
  margin-top: 0.75rem;
}
.mt4 {
  margin-top: 1rem;
}
.mt8 {
  margin-top: 2rem;
}

.ml0 {
  margin-left: 0;
}
.ml1 {
  margin-left: 0.25rem;
}
.ml2 {
  margin-left: 0.5rem;
}
.ml3 {
  margin-left: 0.75rem;
}
.ml4 {
  margin-left: 1rem;
}
.mlAuto {
  margin-left: auto;
}

.mr0 {
  margin-right: 0;
}
.mr1 {
  margin-right: 0.25rem;
}
.mr2 {
  margin-right: 0.5rem;
}
.mr3 {
  margin-right: 0.75rem;
}
.mr4 {
  margin-right: 1rem;
}

.mAuto {
  margin: auto;
}

.mhAuto {
  margin-left: auto;
  margin-right: auto;
}

// Flexbox

.alignStart {
  align-items: flex-start;
}

.alignEnd {
  align-items: flex-end;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}
