@use "src/variables" as v;

.menu {
  position: fixed;
  background: var(--secondary-color);
  height: 100vh;
  width: 325px;
  top: 0;
  right: -325px;
  padding: 0 20px;
  display: flex;
  flex-flow: column nowrap;
  transition: all 0.2s ease-in;
  z-index: 30;
  overflow-y: auto;

  :last-child {
    margin-right: 0;
  }

  a,
  button {
    font-family: v.$headings-font;
    font-size: 1.4rem;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    height: 66px;
    margin-right: 6px;
    border-radius: 5px;
    color: var(--primary-color);
    background: none;
    text-transform: uppercase;

    :visited {
      color: var(--secondary-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      color: var(--link-hover-color);
    }
  }

  .langButton {
    font-size: 1rem;
  }

  button {
    border: none;
    cursor: pointer;
    padding: 0;
  }

  .langIcon {
    margin-left: 0.2rem;
  }

  .closeButton {
    position: absolute;
    top: 0;
    right: 10px;
  }

  @include v.smallMax {
    width: 320px;
    right: -320px;
  }

  .topButtons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .langSelectEnter {
    transform: scaleY(0);
  }

  .langSelectEnterActive {
    transform: scaleY(1);
    transition: all 1s;
  }

  .langSelectExit {
    transform: scaleY(1);
  }

  .langSelectExitActive {
    transform: scaleY(0);
    transition: all 1s;
  }
}

.open {
  right: 0;
}
