@use "src/variables" as v;
.main {
  padding: 0 1rem;
  display: flex;
  flex-flow: column nowrap;
  @include v.mediumMax {
    h2 {
      margin-bottom: 0;
    }
  }
}

.employeesTable {
  border-collapse: collapse;
  table-layout: fixed;
  color: v.$primary-color;
  margin-bottom: 2rem;
  //   width: 100%;

  thead {
    th {
      text-align: start;
    }
  }

  th,
  td {
    width: 33%;
  }

  tbody {
    tr:nth-child(odd) {
      background-color: v.$secondary-color;
    }
  }
}

.message {
  max-width: 400px;
  margin-top: 1rem;
  @include v.mediumMax {
    margin-bottom: 1rem;
  }
}
