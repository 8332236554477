@use "src/variables" as v;

.main {
  padding: 0 1rem 1rem;
  display: flex;
  flex-flow: column nowrap;

  @include v.mediumMax {
    h2 {
      margin-bottom: 0;
    }
  }
}

.employeesTable {
  border-collapse: collapse;
  table-layout: fixed;
  color: v.$primary-color;
  margin: 1rem 0;
  width: 95%;
  max-width: 800px;

  @include v.medium {
    width: 85%;
  }

  thead {
    th {
      text-align: start;
    }
  }

  th,
  td {
    width: 100%;
    border: 1px solid v.$primary-color;
    padding-left: 0.2rem;
    position: relative;

    .caret {
      position: absolute;
      right: 0;
    }
  }

  tbody {
    tr:nth-of-type(odd) {
      background-color: v.$secondary-color;
    }
  }
}

.sort {
  cursor: pointer;

  span {
    text-align: end;
  }
}

.message {
  max-width: 400px;
  margin-top: 1rem;

  @include v.mediumMax {
    margin-bottom: 1rem;
  }
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  background-color: v.$secondary-color-transparent;

  @include v.smallMax {
    align-items: stretch;
    width: 100%;
  }

  input,
  textarea {
    border: none;
    height: 40px;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.5rem;
    width: 100%;

    @include v.small {
      width: 350px;
    }

    @include v.medium {
      width: 500px;
    }
  }

  textarea {
    height: 200px;
    resize: vertical;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
  }

  .button {
    height: 40px;
    border: none;
    margin-top: 1rem;
    background-color: v.$primary-color;
    color: white;
    box-shadow: none;
    transition: 0.3s;
    flex-grow: 1;

    &:active {
      box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.75);
      // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
    }

    &:hover {
      cursor: pointer;
      // background-color: v.$primary-color-transparent;
    }

    @include v.smallMax {
      margin-top: 0.5rem;
    }
  }
}

.errorMessage {
  max-width: calc(370px + 1rem);
  min-height: 1.5rem;
  margin-top: 1rem;
  color: red;
  display: block;
}

.formSentMessageBackground {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.2s;
}

.formSentMessage {
  position: fixed;
  opacity: 0;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 320px;
  top: calc(50% - 50px);
  left: calc(50% - 160px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  z-index: 100;

  button {
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 1.2em;
  }
}

.visible {
  opacity: 1;
}