@use 'src/variables' as v;

.main {
  padding: 0 1rem;
  display: flex;
  flex-flow: column nowrap;
}

.preamble {
  max-width: calc(370px + 1rem);
}

.form {
  display: flex;
  align-items: center;

  @include v.smallMax {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  input {
    border-radius: 5px;
    border: 1px solid black;
    height: 40px;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.20);
    width: 100%;

    @include v.small {
      width: 280px;
    }

  }
}

.personalNumbersListBlock {
  width: calc(100% + 2rem);
  margin: 1rem -1rem;
  background: var(--primary-color);
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;

  h3 {
    color: var(--secondary-color);
  }
}

.list {
  list-style-type: none;

  li:last-child {
    margin-bottom: 0;
  }
}

.listEntry {
  display: flex;
  justify-content: center;
  min-width: 13rem;
  position: relative;
  margin-bottom: 0.5rem;

  button {
    background: none;
    cursor: pointer;
    position: absolute;
    color: white;
    border: none;
    right: 0;
  }
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.button {
  border-radius: 5px;
  height: 40px;
  border: 1px solid #5886e8;
  background-color: v.$primary-color;
  color: white;
  transition: 0.3s;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  flex-grow: 1;

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: v.$primary-color-transparent;
  }

  @include v.small {
    width: 90px;
    align-self: flex-end;
  }

  @include v.smallMax {
    margin-top: 0.5rem;
  }
}

.errorMessage {
  max-width: calc(370px + 1rem);
  min-height: 1.5rem;
  margin-top: 1rem;
  color: red;
  display: block;
}
