@use "src/variables" as v;

.main {
  display: flex;
  flex-flow: column nowrap;

  @include v.mediumMax {
    h2 {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: none;
  }
}

.postsContainer {
  max-width: 900px;
  align-self: stretch;
  margin: 0 auto 1rem;
}

.message {
  max-width: 400px;
  margin-top: 1rem;

  @include v.mediumMax {
    margin-bottom: 1rem;
  }
}

.postContainer {
  position: relative;
}

.pinButton {
  position: absolute;
  top: 5px;
  right: 5px;
  margin: 0;
  height: 30px;
  border: none;
  background-color: v.$primary-color;
  color: white;
  box-shadow: none;
  transition: 0.3s;

  &:active {
    box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.75);
    // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  }

  &:hover {
    cursor: pointer;
    // background-color: v.$primary-color-transparent;
  }

  @include v.smallMax {
    margin-top: 0.5rem;
  }
}

.tagList {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  max-width: 600px;

  :last-child {
    margin-right: 0;
  }
}

.tag {
  color: v.$primary-color;
  background: white;
  height: 100%;
  padding: 0.2rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border: solid v.$primary-color 2px;

  @include v.mediumMax {
    margin-top: 0.5rem;
  }
}

.checked {
  background: v.$primary-color;
  color: white;
}