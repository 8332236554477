@use "src/variables" as v;

.document {
  display: flex;
  flex-flow: column nowrap;
  margin: 1rem auto 0;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.25);
  flex: 2 1 500px;

  @include v.medium {}

  .documentInfo {
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;

    .buttonGroup {
      flex: 0 0 auto;

      button:first-of-type {
        margin-right: 0.5rem;
      }
    }

    a {
      color: v.$primary-color;
    }
  }

  .documentSign {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.1);
    line-height: 32px;

    .notSigned {
      color: red;
    }

    .signed {
      color: green;
    }
  }



  .deleteButton {
    font-size: 24px;
    padding: 0;
    line-height: 100%;
    background: none;
    cursor: pointer;
    color: v.$primary-color;
    border: none;
    right: 0;
    transition: all 0.2s;
  }
}

.wideDocument {
  width: 90vw;

  @include v.medium {
    width: 700px;
  }
}

.editDocumentForm {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.inputGroup {
  display: flex;
  flex-direction: column;
}

.tagList {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  max-width: 600px;

  :last-child {
    margin-right: 0;
  }
}

.tag {
  color: v.$primary-color;
  background: white;
  height: 100%;
  padding: 0 0.2rem;
  border-radius: 8px;
  margin-right: 0.5rem;
  cursor: pointer;
  border: solid v.$primary-color 2px;

  @include v.smallMax {
    margin-top: 0.5rem;
  }
}

.tagLi {
  position: relative;
  margin-right: 0.3rem;
  margin-bottom: 0.3rem;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.tagDiv {
  color: v.$primary-color;
  background: white;
  height: 100%;
  width: 100%;
  padding: 0.2rem;
  border-radius: 8px;
}

input[type="checkbox"]:checked~.tagDiv {
  background: v.$primary-color;
  color: white;
}

.confirmationBackground {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s;
  z-index: 2;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 30%;
  border: none;
  border-radius: 5px;
  height: 65%;
  background-color: v.$primary-color;
  color: white;
  box-shadow: none;
  transition: 0.3s;
  line-height: 100%;

  &:active {
    box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.25);
    // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  }

  &:hover {
    cursor: pointer;
    // background-color: v.$primary-color-transparent;
  }

  p {
    line-height: 1.5rem;
  }

  img {
    max-height: 120px;
  }

  .date {
    font-size: 0.75rem;
  }

}

.updateButton {
  align-self: flex-end;
  margin-top: 1rem;
  width: auto;
}

.confirmationMessage {
  padding-top: 1rem;
  position: fixed;
  opacity: 0;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  width: 320px;
  top: calc(50% - 60px);
  left: calc(50% - 160px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  z-index: 100;

  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    margin-top: 1rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);

    .delete {
      background-color: red;
      color: white;

      &:active {
        box-shadow: inset 5px 5px 15px rgba(109, 1, 1, 0.75);
        // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
      }
    }
  }
}

.visible {
  opacity: 1;
}

.signMessage {
  height: 320px;
  top: calc(50% - 160px);
  justify-content: space-between;
  padding: 1rem;

  .bankIdText {
    color: black;
  }
}

.changeDeviceButton {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}