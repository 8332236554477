@use "src/variables" as v;

.main {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.adminView {
  padding-top: 3rem;
  flex-flow: row wrap;
}

.post {
  position: relative;
  display: flex;
  flex: 3 auto 400px;
  flex-flow: column;
  margin: 1rem;
  padding: 2.5rem 1rem 1rem 1rem;
  width: 90vw;
  min-width: 90%;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.25);
  background-image: url("../../images/pin.png");
  background-position: top right 50%;
  background-repeat: no-repeat;

  @include v.small {
    min-width: 400px;
    max-width: 500px;
  }

  @include v.medium {
    min-width: 500px;
    max-width: 600px;
  }

  h3 {
    text-transform: uppercase;
    align-self: center;
  }

  p {
    line-height: 1.5rem;
  }

  img {
    max-height: 120px;
  }

  .authorContainer {
    display: flex;
    flex-flow: column nowrap;
  }

  .author {
    font-size: 0.75rem;
  }

  .date {
    font-size: 0.75rem;
  }
}

.statistics {
  display: flex;
  flex: 1 auto 200px;
  flex-flow: column;
  align-items: center;
  margin: 1rem;
  padding: 2.5rem 1rem 1rem 1rem;
  width: 90vw;
  min-width: 90%;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.25);

  h3 {
    text-transform: uppercase;
    align-self: center;
  }

  @include v.small {
    min-width: 200px;
    max-width: 300px;
  }
}

.deleteButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 1rem;
  right: 1rem;
  margin: 0;
  font-size: 36px;
  height: 40px;
  width: 40px;
  padding: 0;
  background: none;
  cursor: pointer;
  color: v.$primary-color;
  border: none;
  transition: all 0.2s;
}

.editButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1rem;
  right: 1rem;
  margin: 0;
  font-size: 36px;
  height: 40px;
  width: 40px;
  padding: 0;
  background: none;
  cursor: pointer;
  color: v.$primary-color;
  border: none;
  transition: all 0.2s;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  width: 30%;
  border: none;
  border-radius: 5px;
  height: 65%;
  background-color: white;
  box-shadow: none;
  transition: 0.3s;
  line-height: 100%;

  &:active {
    box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.25);
    // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
  }

  &:hover {
    cursor: pointer;
    // background-color: v.$primary-color-transparent;
  }

  p {
    line-height: 1.5rem;
  }

  img {
    max-height: 120px;
  }

  .date {
    font-size: 0.75rem;
  }
}

.confirmationMessage {
  padding-top: 1rem;
  position: fixed;
  opacity: 0;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.75);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 120px;
  width: 320px;
  top: calc(50% - 60px);
  left: calc(50% - 160px);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  margin: auto;
  transition: all 0.2s;
  text-align: center;
  z-index: 100;

  .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-grow: 1;
    margin-top: 1rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.1);

    .delete {
      background-color: red;
      color: white;
      &:active {
        box-shadow: inset 5px 5px 15px rgba(109, 1, 1, 0.75);
        // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
      }
    }
  }
}

.confirmationBackground {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s;
}

.notSigned {
  display: flex;
  position: relative;
  flex: 1 auto 200px;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
  width: 90vw;
  min-width: 90%;
  height: 100px;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.25);

  h3 {
    text-transform: uppercase;
    align-self: center;
  }

  @include v.small {
    min-width: 200px;
    max-width: 300px;
  }
}

.tagList {
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  :last-child {
    margin-right: 0;
  }
}

.tagDiv {
  color: v.$primary-color;
  background: white;
  border: 1px solid v.$primary-color;
  padding: 0.2rem;
  border-radius: 8px;
  margin-right: 0.3rem;
}

.expandButton {
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  right: 5px;
  width: 30px;
  height: 30px;
  font-size: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: v.$primary-color;
}

.expanded {
  height: 300px;
  overflow-y: scroll;
}

.visible {
  opacity: 1;
}
