@use "src/variables" as v;

.post {
  display: flex;
  flex-flow: column;
  margin: 1rem auto 0;
  padding: 2.5rem 1rem 2rem 1rem;
  width: 90vw;
  min-width: 90%;
  height: 200px;
  box-shadow: 5px 5px 12px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  @include v.small {
    min-width: 400px;
    max-width: 500px;
  }

  @include v.medium {
    min-width: 500px;
    max-width: 600px;
  }

  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px; /* Adjust the height of the faded effect */
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }

  .readMoreBtn {
    display: block;
    position: absolute;
    margin: 0;
    width: 100%;
    bottom: 0; /* Half of the height of the faded effect */
    right: 0;
    z-index: 10;
    background-color: v.$secondary-color-transparent;
    border: none;
    color: v.$primary-color; /* Adjust the color of the "Read More" button */
    cursor: pointer;
  }

  h3 {
    text-transform: uppercase;
    align-self: center;
  }

  p {
    line-height: 1.5rem;
  }

  img {
    max-height: 120px;
  }

  .bodyText {
    overflow: hidden;
    color: black;
  }

  .authorContainer {
    display: flex;
    flex-flow: column nowrap;
  }

  .author {
    font-size: 0.75rem;
  }

  .date {
    font-size: 0.75rem;
  }
}

.isPinned {
  background-image: url("../../images/pin.png");
  background-position: top right 50%;
  background-repeat: no-repeat;
}
