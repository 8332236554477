@use "src/variables" as v;

.notSigned {
  display: flex;
  position: relative;
  flex: 1 auto 200px;
  flex-flow: column nowrap;
  align-items: center;
  margin: 1rem;
  padding: 0.5rem;
  width: 90vw;
  min-width: 90%;
  height: 93px;
  overflow: hidden;
  transition: all 0.3s;
  box-shadow: 5px 5px 12px 2px rgba(0, 0, 0, 0.25);

  @include v.small {
    min-width: 200px;
    max-width: 300px;
  }
}

.expandButton {
  position: sticky;
  align-self: flex-end;
  bottom: 0;
  right: 5px;
  width: 30px;
  height: 30px;
  font-size: 2rem;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  color: v.$primary-color;
}

.expanded {
  height: 300px;
  overflow-y: scroll;
}