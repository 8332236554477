@use "src/variables" as v;

.main {
  padding: 0 1rem 2rem;
  display: flex;
  flex-flow: column nowrap;

  @include v.mediumMax {
    h2 {
      margin-bottom: 0;
    }
  }
}

.documentGroup {
  display: flex;
  width: 90%;
  max-width: 900px;
  flex-flow: row nowrap;
  align-items: flex-start;

  // div:first-of-type {
  //   min-width: 0;
  //   max-width: 600px;
  //   flex: 3 1 auto;
  // }

  @include v.mediumMax {
    flex-flow: row wrap;
  }
}

.message {
  max-width: 400px;
  margin-top: 1rem;

  @include v.mediumMax {
    margin-bottom: 1rem;
  }
}