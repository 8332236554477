@use "src/variables" as v;

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.5rem;
  background-color: v.$secondary-color-transparent;

  @include v.smallMax {
    align-items: stretch;
    width: 100%;
  }

  input,
  textarea {
    border: none;
    height: 40px;
    width: 100%;
    margin-bottom: 1rem;
    padding-left: 0.5rem;

    @include v.small {
      width: 350px;
    }

    @include v.medium {
      width: 500px;
    }
  }

  textarea {
    height: 200px;
    resize: vertical;
  }

  .nameInputGroup {
    display: flex;
    flex-direction: column;

    @include v.medium {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      div:nth-child(1) {
        margin-right: 0.5rem;
      }
    }

    input,
    textarea {
      border: none;
      height: 40px;
      width: 100%;
      margin-bottom: 1rem;
      padding-left: 0.5rem;

      @include v.small {
        width: 350px;
      }

      @include v.medium {
        width: 100%;
      }
    }

    .singleNameGroup {
      display: flex;
      flex-flow: column nowrap;
      flex-grow: 1;
    }
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
  }

  .button {
    height: 40px;
    border: none;
    margin-top: 1rem;
    background-color: v.$primary-color;
    color: white;
    box-shadow: none;
    transition: 0.3s;
    flex-grow: 1;

    &:active {
      box-shadow: inset 5px 5px 15px rgba(0, 0, 0, 0.75);
      // box-shadow: inset 10px 10px 15px rgba(70, 70, 70, 0.12);
    }

    &:hover {
      cursor: pointer;
      // background-color: v.$primary-color-transparent;
    }

    @include v.smallMax {
      margin-top: 0.5rem;
    }
  }
}
